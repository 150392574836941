<template>
  <!-- Removed margin classes to utilize full screen width -->
  <div class="scrumboard-container">
    <scrumbar @openAddBoard="openAddBoard" />

    <!-- Scrollable wrapper for horizontal scrolling -->
    <div class="scrum-scroll-wrapper pt-2 pb-14">
      <div class="scrum-row">
        <!-- Iterate through each board in the boardList -->
        <div
          v-for="item in boardList"
          :key="item.id"
          class="scrum-column"
        >
          <v-card outlined color="grey lighten-4" class="fill-height ma-0 pa-0">
            <v-card-title>
              <div class="d-flex justify-space-between">
                <h3>{{ handleName(item.name) }}</h3>
              </div>
              <div class="flex-grow-1"></div>
              <div class="mr-1" v-if="item.tickets.length !== 0">
                <b>{{ item.tickets.length }}</b>
              </div>
            </v-card-title>
            <v-card-text class="column-content">
              <!-- Button to add a new ticket -->
              <v-btn
                block
                dense
                color="primary"
                @click="createTicket(item)"
                class="add-button"
              >
                <v-icon left>add</v-icon>
                {{ $t("tickets.scrum.addTicket") }}
              </v-btn>

              <!-- Draggable area for tickets -->
              <draggable
                class="draggable-column"
                :list="item.tickets"
                ghost-class="ghost"
                group="tickets"
                :emptyInsertThreshold="-400"
                @change="checkTicketMove($event, item)"
                :style="{ overflowY: item.tickets.length > 0 ? 'auto' : 'hidden' }"
              >
                <transition-group
                  name="flip-list"
                  class="d-inline-block fill-width fill-height"
                >
                  <card
                    v-for="element in item.tickets"
                    :key="element._id"
                    :item="element"
                    @reloadData="reloadDataFunc"
                    @click="openCard(element)"
                  />
                </transition-group>
              </draggable>
            </v-card-text>
          </v-card>
        </div>

        <!-- Column to add a new board -->
        <div class="scrum-column">
          <v-card
            outlined
            color="grey lighten-4"
            class="d-flex align-center fill-height"
            v-ripple
            @click="openAddBoard"
          >
            <v-btn color="primary" text class="mx-auto" style="width: 80%;">
              <v-icon left>add</v-icon>
              {{ $t("tickets.scrum.addColumn") }}
            </v-btn>
          </v-card>
        </div>
      </div>
    </div>

    <!-- Additional Components -->
    <ticketForm
      ref="ticketForm"
      :data="ticketForm"
      @reloadData="reloadDataFunc"
    />
    <deleteDialog
      @triggerDelete="deleteItem"
      :item="deleteDialog.item"
      :deleteDialog="deleteDialog"
    />
    <ticketView
      ref="ticketView"
      @reloadData="reloadDataFunc"
      @editTicket="editTicket"
      @openDeleteDialog="openDeleteDialog"
      @sendEmailTicket="sendEmailTicket"
      :data="ticketView"
    />
    <emailForm ref="emailForm" :data="emailForm" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import card from "./card.vue";
import scrumbar from "./scrumbar.vue";
import ticketForm from "./form/ticketForm.vue";
import ticketView from "./ticketView/ticketView.vue";
import emailForm from "./form/emailForm.vue";
import deleteDialog from "../../../components/_utils/dataTableHelpers/actions/deleteDialog.vue";

export default {
  metaInfo: {
    title: "Scrumboard",
  },
  components: {
    draggable,
    card,
    scrumbar,
    ticketForm,
    ticketView,
    deleteDialog,
    emailForm,
  },
  computed: {
    ...mapGetters(["populatedBoardsPending"]),
  },
  mounted() {
    this.init();
  },
  watch: {
    "$route.params.mode": function () {
      this.init();
    },
  },
  data() {
    return {
      boardList: [],
      deleteDialog: {
        open: false,
        item: null,
        title: this.$t("tickets.scrum.deleteTicket"),
        description: "",
      },
      ticketForm: {
        open: false,
        title: this.$t("tickets.scrum.createTicket"),
        item: null,
        method: "new",
      },
      emailForm: {
        open: false,
        title: this.$t("tickets.scrum.emailTicket"),
        item: null,
      },
      ticketView: {
        open: false,
        item: null,
      },
    };
  },
  methods: {
    init() {
      if (this.$route.params.mode === "user") {
        this.$store
          .dispatch(
            "getBoardByUser",
            JSON.parse(localStorage.getItem("userData"))._id
          )
          .then((r) => {
            this.boardList = r;
          });
      } else {
        this.$store.dispatch("getBoard").then((r) => {
          this.boardList = r;
        });
      }
    },
    reloadDataFunc() {
      this.init();
      this.ticketView = {
        open: false,
        item: null,
      };
    },
    checkTicketMove(event, item) {
      let added = event.added;
      if (added) {
        added.element.board = item._id;
        this.$store.dispatch("updateTicket", added.element);
      }
    },
    createTicket(row) {
      this.ticketForm = {
        open: true,
        title: this.$t("tickets.scrum.createTicket"),
        item: null,
        method: "new",
      };
      if (row) {
        this.$refs.ticketForm.presetData({ board: row._id });
      }
    },
    editTicket(item) {
      this.ticketForm = {
        open: true,
        title: this.$t("tickets.scrum.editTicket"),
        item: null,
        method: "edit",
      };
      this.$refs.ticketForm.triggerEdit({ ...item });
    },
    sendEmailTicket(item) {
      this.emailForm = {
        open: true,
        title: this.$t("tickets.scrum.emailTicket"),
        item: item,
      };
    },
    openCard(item) {
      this.$store.dispatch("getSingleTicket", item._id).then((r) => {
        this.$store
          .dispatch("getSettingData", "standardClosedBoard")
          .then((item) => {
            r.board.final = item.value === r.board.id;
            this.ticketView = {
              open: true,
              item: r,
            };
          });
      });
    },
    openDeleteDialog(item) {
      this.ticketView.open = false;
      this.deleteDialog.open = true;
      this.deleteDialog.item = item;
      this.deleteDialog.description = this.$t("tickets.scrum.deleteTitle", {
        title: item.title,
      });
    },
    deleteItem(item) {
      this.$store.dispatch("deleteTicket", item).then(() => {
        this.reloadDataFunc();
        this.deleteDialog.open = false;
      });
    },
    openAddBoard() {
      this.$router.push({ name: "boardSettings" });
    },
    handleName(name) {
      if (localStorage.getItem("locale") !== "en") return name;
      switch (name) {
        case "Erledigt":
          return "Done";
        case "Offen":
          return "Open";
        case "In Bearbeitung":
          return "In process";
        default:
          return name;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$border: 1px solid rgba(black, 10%);

.scrumboard-container {
  /* Occupies full width */
  width: 100%;
  /* Optional: Set background color if needed */
  /* background-color: #f0f0f0; */
}

.scrum-scroll-wrapper {
  overflow-x: auto;
  width: 100%;
  /* Optional: Customize scrollbar appearance */
  /*
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.05);
  }
  */
}

.scrum-row {
  display: flex;
  flex-direction: row;
  gap: 15px; /* Spacing between columns */
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap; /* Prevent wrapping to next line */

  .scrum-column {
    border: $border;
    min-width: 400px;
    flex-shrink: 0; /* Prevent columns from shrinking */
    position: relative;

    .column-content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .add-button {
        flex: 0 1 auto;
      }

      .draggable-column {
        flex: 1 1 auto;
        max-height: 1000px;
        padding: 16px 16px 24px 0;
        border-top: $border;
        /* Overflow controlled via inline styles in the template */
      }

      .fill-width {
        width: 100%;
      }
    }
  }
}

.sortable {
  cursor: move;
}

.sortable-drag {
  opacity: 1;
}

.flip-list-move {
  transition: transform 0.6s;
}

.ghost {
  border-left: 1px solid #43a047;
  opacity: 0.7;
}

.list-group {
  min-height: 100%;
}
</style>