<template>
  <v-dialog v-model="isOpen" max-width="800">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <div>
          {{ this.$t("instructions.instructionsDialog.assignedNames.title") }} test
        </div>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form" @submit="onSubmit">
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete v-model="risk.responsible" :items="userDataMap" :loading="getAllUsersStatus" hide-no-data
                item-text="Description" item-value="id" label="Verantwortlicher" data-cy="users" dense
                :rules="config.rules"></v-autocomplete>
                
              <v-text-field v-model="nameInput" outlined :label="$t('instructions.instructionsDialog.form.name')"
                :placeholder="$t('instructions.instructionsDialog.form.placeholder')
                  " :rules="nameRules" required append-icon="mdi-plus" @click:append="onSubmit()"></v-text-field> lol
            </v-col>
          </v-row>
        </v-form>
        <v-list>
          <template v-for="(assignedName, index) of assignedNames">
            <v-list-item :key="assignedName">
              <v-list-item-content>
                {{ assignedName }}
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="deleteAssignedName(index)">
                  <v-icon color="grey lighten-1">mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider v-if="index < assignedNames.length - 1" :key="index"></v-divider>
          </template>
        </v-list>
      </v-card-text>

      <v-spacer />

      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" v-on:click="isOpen = false">{{
          $t("general.back")
          }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "instructionTableAssignedNamesDialog",
  props: ["assignedNames"],
  data() {
    return {
      isOpen: false,
      valid: true,
      nameInput: "",
      nameRules: [(v) => !!v || "Dieses Feld wird benötigt."],
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    onSubmit($event) {
      $event?.preventDefault();
      this.$refs.form.validate();

      if (this.valid) {
        this.$emit("saveInstruction", [this.nameInput, ...this.assignedNames]);
        this.nameInput = "";
        this.$refs.form.resetValidation();
      }
    },
    deleteAssignedName(index) {
      const copyAssignedNames = [...this.assignedNames]
      copyAssignedNames.splice(index, 1);
      this.$emit("saveInstruction", copyAssignedNames);
    }
  },
};
</script>
