<template>
  <div class="managed_instruction_assignments_table">
    <v-data-table v-bind:headers="headers" v-bind:items="allManagedInstructions"
      :options.sync="tableDataGetter.pagination" :footer-props="{
        itemsPerPageOptions: tableDataGetter.pagination.rowsPerPageItems,
        itemsPerPageText: $t('general.rowsPerPage'),
      }" @click:row="openAssigned($event._id)">
      <template v-slot:item.previewImage="{ item }">
        <v-img v-bind:src="item.previewImage" width="100" height="100" class="my-2 rounded elevation" />
      </template>
      <template v-slot:item.name="{ item }">
        {{ getFieldByLocale(item.name) }}
      </template>
      <template v-slot:item.description="{ item }">
        {{ getFieldByLocale(item.description) }}
      </template>
      <template v-slot:item.assigned="{ item }">
        <v-chip color="primary" outlined class="px-4" ripple v-on:click.stop="openAssigned(item._id)">
          {{ item.assignedCount }}
        </v-chip>
      </template>
      <template v-slot:item.finished="{ item }">
        <v-chip color="primary" outlined class="px-4" ripple v-on:click.stop="openAssigned(item._id)">
          {{ item.assignedCount - item.openAssignmentCount }}
        </v-chip>
      </template>
      <template v-slot:item.open="{ item }">
        <v-chip color="red" outlined class="px-4" ripple v-on:click.stop="openOpenAssignments(item._id)">
          {{ item.openAssignmentCount }}
        </v-chip>
      </template>
    </v-data-table>

    <managed-instruction-assignments-assigned-dialog ref="assigned" v-bind:instruction-id="dialogAssigned.instructionId"
      @dismiss="initPage()" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ManagedInstructionAssignmentsAssignedDialog from "@/views/managedInstruction/managedInstructionAssignmentsAssignedDialog.vue";

export default {
  components: { ManagedInstructionAssignmentsAssignedDialog },
  computed: {
    ...mapGetters([
      "allManagedInstructions",
      "allManagedInstructionsPending",
      "tableDataGetter",
    ]),
    headers() {
      return [
        { text: "", value: "previewImage", align: "left" },
        {
          text: this.$t("managedInstructions.tableHeader.instruction"),
          value: "name",
        },
        {
          text: this.$t("managedInstructions.tableHeader.description"),
          value: "description",
        },
        {
          text: this.$t("managedInstructions.tableHeader.assigned"),
          value: "assigned",
          align: "center",
        },
        {
          text: this.$t("managedInstructions.tableHeader.finished"),
          value: "finished",
          align: "center",
        },
        {
          text: this.$t("managedInstructions.tableHeader.open"),
          value: "open",
          align: "center",
        },
      ];
    },
  },
  created() {
    this.initPage();
  },
  data: () => ({
    dialogAssigned: {
      instructionId: undefined,
    },
    localeMap: {
      de: "GER",
      en: "EN",
    },
  }),
  methods: {
    initPage() {
      this.$store.dispatch("getAllManagedInstructions");
    },
    getFieldByLocale(fieldArray) {
      // Map i18n locale to backend language code
      const backendLang = this.localeMap[this.$i18n.locale];
      const field = fieldArray.find((item) => item.language === backendLang);
      return field ? field.name || field.description : "";
    },
    _open(instructionId, tabNumber) {
      this.dialogAssigned.instructionId = instructionId;
      this.$nextTick(() => this.$refs.assigned.openDialog(tabNumber));
    },
    openAssigned(instructionId) {
      this._open(instructionId, 0);
    },
    openOpenAssignments(instructionId) {
      this._open(instructionId, 1);
    },
  },
};
</script>

<style>
div.managed_instruction_assignments_table th[role="columnheader"].sortable {
  white-space: nowrap;
}

div.managed_instruction_assignments_table tr {
  cursor: pointer;
}
</style>